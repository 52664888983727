






























import PresentationViewer from "@/components/PresentationViewer.vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Project, Unit } from "@/entities";
import { getModule } from "vuex-module-decorators";
import {
  Application,
  GetProjectScoreParams,
  GetUnitActionParams,
  ScoreProjectActionParams
} from "@/state/Application/application";
import { namespace } from "vuex-class";

const Store = namespace("Application");
const _store = getModule(Application);

@Component({
  name: "view-unit",
  components: {
    PresentationViewer
  }
})
export default class ViewPresentation extends Vue {
  currentSlideIdx = 0;

  @Prop({ required: true })
  public region!: string;

  @Prop({ required: true })
  public year!: number;

  @Prop({ required: true })
  public unitId!: string;

  @Prop({ required: true })
  public presentationId!: string;

  @Watch("project", { immediate: true })
  onPresentationIdChanged(val: Project) {
    if (val && !val.scores && _store.judgeId !== null) {
      _store.getProjectScore(new GetProjectScoreParams(val.id, _store.judgeId));
    }
  }

  @Store.Getter
  public unit!: Unit;

  @Store.Getter
  public judgeId!: string;

  get project(): Project | null {
    if (this.unit && this.unit.projects) {
      return this.unit.projects[this.presentationId];
    }

    return null;
  }

  projectScored(score: number[], finalized: boolean) {
    if (this.judgeId) {
      _store.scoreProject(
        new ScoreProjectActionParams(
          this.presentationId,
          this.judgeId,
          score,
          finalized
        )
      );
    }
  }

  mounted(): void {
    if (!this.unit) {
      _store.getUnit(
        new GetUnitActionParams(this.region, this.year, this.unitId)
      );
    }
  }

  backToUnit(): void {
    this.$router.back();
  }
}
