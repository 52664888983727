




















































import {Component, Vue, Prop} from "vue-property-decorator";
import {Project} from "@/entities";
import {Environment} from "@/environment";
import ScoreEntry from "@/components/ScoreEntry.vue";
import {namespace} from "vuex-class";

const Store = namespace("Application");

@Component({
  components: {ScoreEntry}
})
export default class PresentationViewer extends Vue {
  currentSlideIdx = 0;
  presentationHeight: number | null = 700;
  exitRequest = 0;

  @Prop({required: true})
  public unitId!: string;

  @Prop({required: true})
  public project!: Project;

  @Prop()
  public judgeId!: string | null;

  @Store.Getter("fullscreen")
  public fullscreen!: boolean;

  get currentSlide(): number {
    return this.currentSlideIdx + 1;
  }

  get presentationPath(): string {
    return `${Environment.PresentationStorageUrl}/${this.project.presentationPath}`;
  }

  get currentSlideUrl(): string {
    return `${this.presentationPath}/${this.currentSlideIdx}.jpg`;
  }

  get nextSlideUrl(): string {
    let idx = this.currentSlideIdx;

    if (this.currentSlide < this.project.slideCount) {
      ++idx;
    }

    return `${this.presentationPath}/${idx}.jpg`;
  }

  get audioUrl(): string | null {
    return this.project.audioFilename
        ? `${this.presentationPath}/${this.project.audioFilename}`
        : null;
  }

  public nextSlide(): void {
    this.exitRequest = 0;
    if (this.currentSlide < this.project.slideCount) {
      this.currentSlideIdx++;
    } else if (this.fullscreen) {
      this.$emit("back");
    }
  }

  public previousSlide(): void {
    this.exitRequest = 0;
    if (this.currentSlide > 1) {
      this.currentSlideIdx--;
    }
  }

  public requestExit(): void {
    if (!this.fullscreen) return;
    if (this.exitRequest > 1) {
      this.$emit("back");
    }
    else {
      ++this.exitRequest;
    }
  }

  public keyup(e: KeyboardEvent): void {
    if (e.ctrlKey || e.altKey) {
      return;
    }

    if (e.key == "ArrowLeft" || e.key == "ArrowUp" || e.key == "PageUp") {
      this.previousSlide();
      e.stopPropagation();
    } else if (e.key == "ArrowRight" || e.key == "ArrowDown" || e.key == "PageDown") {
      this.nextSlide();
      e.stopPropagation();
    }
  }

  public resize(): void {
    if (this.fullscreen) {
      this.presentationHeight = null;
    } else {
      this.presentationHeight = window.innerHeight - 250;
    }
  }

  public projectScored(score: number[], finalized: boolean) {
    this.$emit("scored", score, finalized);
  }

  mounted(): void {
    window.addEventListener("fullscreenchange", this.resize);
    window.addEventListener("keydown", this.keyup);
    window.addEventListener("resize", this.resize);
    this.resize();
  }

  beforeDestroy(): void {
    window.removeEventListener("fullscreenchange", this.resize);
    window.removeEventListener("keydown", this.keyup);
    window.removeEventListener("resize", this.resize);
  }
}
