export class CategoryScoreTitles {
  public static GetScoreTitles(category: string): string[] {
    if (!category) return ["", "", "", "", ""];

    category = category.toUpperCase();

    switch (category) {
      case "MATHEMATICS":
        return [
          "Fulfillment of Purpose",
          "Content",
          "Development",
          "Presentation",
          "Judge's Opinion"
        ];
      case "COMPUTER SCIENCE":
        return [
          "Statement of Problem",
          "Methods",
          "Fulfillment of Purpose",
          "Presentation",
          "Judge's Opinion"
        ];
      case "ENGINEERING":
        return [
          "Problem/Purpose",
          "Methodology",
          "Fulfillment of Purpose",
          "Presentation",
          "Judge's Opinion"
        ];
      default:
        return [
          "Scientific Thought",
          "Experimental Methods",
          "Analytical Approach",
          "Presentation",
          "Judge's Opinion"
        ];
    }
  }
}
