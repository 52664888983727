

































































import { Vue, Component, Model, Prop, Watch } from "vue-property-decorator";
import { CategoryScoreTitles } from "@/categoryScoreTitles";

@Component({
  name: "score-entry"
})
export default class ScoreEntryComponent extends Vue {
  private drawer = true;
  private collapsed = true;
  private scoreLabels = ["1", "2", "3", "4", "5"];
  private buttonDisabled = false;

  @Model("change")
  public score!: number[];

  @Prop({ required: true })
  public scoreFinalized!: boolean;

  @Watch("scoreFinalized")
  public scoreFinalizedChanged() {
    this.buttonDisabled = false;
  }

  @Prop({ required: true })
  public judgeId!: string | null;

  @Prop({ required: true })
  public category!: string;

  public get finalScore(): number {
    if (!this.score) {
      return 0;
    }
    return this.score.reduce((sum, n) => sum + n, 0) / 5.0;
  }

  public get color(): string {
    if (this.finalScore >= 4.0) {
      return "blue";
    } else if (this.finalScore >= 3.0) {
      return "red";
    }
    return "#FFD700";
  }

  public get award(): number | null {
    if (this.finalScore >= 4.0) {
      return 1;
    } else if (this.finalScore >= 3.0) {
      return 2;
    } else if (this.finalScore > 1.0) {
      return 3;
    }

    return null;
  }

  public getScoreDescription(index: number): string {
    return CategoryScoreTitles.GetScoreTitles(this.category)[index];
  }

  public finalizeScore(finalized: boolean) {
    this.buttonDisabled = true;
    this.$emit("change", this.score, finalized);
  }

  public updateScore(): void {
    this.$emit("change", this.score, this.scoreFinalized);
  }
}
